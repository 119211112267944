const $ = (selector) => {
  const result = document.querySelectorAll(selector);

  if (result.length === 1) return result[0];

  return result;
};

// Navbar
const menuBtn = $('.menu-btn');
const navbar = $('.navbar__nav');

menuBtn.addEventListener('click', () => {
  menuBtn.classList.toggle('open');
  navbar.classList.toggle('show');
});

//Type writer
new Typewriter('#typewriter', {
  strings: ['Demolition', 'Framing', 'Drywall', 'Painting'],
  autoStart: true,
  loop: true,
});
